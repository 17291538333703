import { render, staticRenderFns } from "./formBonos.vue?vue&type=template&id=dc74b70e&scoped=true&"
import script from "./formBonos.vue?vue&type=script&lang=js&"
export * from "./formBonos.vue?vue&type=script&lang=js&"
import style0 from "./formBonos.vue?vue&type=style&index=0&id=dc74b70e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc74b70e",
  null
  
)

export default component.exports